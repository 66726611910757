import { CheckCircle, DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  ImageListItem,
  Tooltip,
  Typography,
  darken,
} from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import PdfIcon from "../../../../assets/icons/pdf_icon.svg";
import { openElement } from "../../../../store/features/base/modalsSlice";

const ReceiptItem = ({
  file = {},
  onClickRemove,
  isPDF,
  isEditable,
  enableProcessingIndicators = false,
}) => {
  const dispatch = useDispatch();
  const { url, id, filename, status } = file || {};

  const loading = status === "processing";
  const completed = status === "completed";

  const handleImageClick = (e) => {
    e.stopPropagation();
    if (isPDF) {
      dispatch(
        openElement({
          name: "pdfViewer",
          payload: {
            url,
            filename,
          },
        })
      );
      return;
    }
    const receipt = document.getElementById(`receipt-${id}`);
    if (receipt) {
      receipt.click();
    }
  };

  return (
    <ImageListItem
      component={"div"}
      sx={{
        cursor: "pointer",
        position: "relative",
        borderRadius: 2,
        overflow: "hidden",
        border: 2,
        borderColor: "transparent",
        backgroundColor: "background.default",
        ":hover": {
          borderColor: "primary.main",
        },
      }}
      onClick={handleImageClick}
    >
      <Box
        display={"flex"}
        alignItems="center"
        bgcolor={"rgb(0,0,0,.7)"}
        position={"absolute"}
        width="100%"
        zIndex={5}
        top={0}
        height={30}
        px={1}
        pr={0.5}
      >
        <Tooltip title={filename}>
          <Typography
            noWrap
            fontWeight={"600"}
            fontSize={".8rem"}
            variant="body2"
            color={"white"}
          >
            {filename}
          </Typography>
        </Tooltip>
        <Box flex={1} />
        {isEditable && (
          <Tooltip title={"Remover"}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onClickRemove(id);
              }}
              color="inherit"
              sx={{
                color: "#FFF",
                ":hover": {
                  color: darken("#FFF", 0.1),
                  bgcolor: "rgba(255, 255, 255, .2)",
                },
              }}
              size="small"
            >
              <DeleteOutlineOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {isPDF ? (
        <Box
          height={200}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={PdfIcon} width={"40%"} />
        </Box>
      ) : (
        <img
          id={`receipt-${id}`}
          data-fancybox="gallery"
          src={url}
          alt={filename}
          loading="lazy"
        />
      )}
      {enableProcessingIndicators && (completed || loading) && (
        <Box
          sx={{ bgcolor: "rgba(255,255,255,.5)" }}
          position={"absolute"}
          left={0}
          top={0}
          right={0}
          bottom={0}
          height={"100%"}
          zIndex={9}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            p={0.1}
            width={20}
            height={20}
            borderRadius={100}
            bgcolor={"background.default"}
          >
            {loading ? (
              <CircularProgress color="primary" size={15} thickness={6} />
            ) : (
              completed && <CheckCircle color="success" fontSize="small" />
            )}
          </Box>
        </Box>
      )}
    </ImageListItem>
  );
};

export default memo(ReceiptItem);
