import { InfoOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

const Error = ({ onClose }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      height={300}
      p={2}
      sx={{ color: "#FFF" }}
    >
      <InfoOutlined fontSize="large" sx={{ color: "#FFF" }} />
      <Typography fontWeight={600} mt={2} textAlign={"center"} variant="h5">
        Ops, ocorreu um erro ao carregar o PDF.
      </Typography>
      <Button
        onClick={onClose}
        sx={{ mt: 3, borderColor: "#FFF" }}
        variant="outlined"
        color="inherit"
      >
        Fechar
      </Button>
    </Box>
  );
};

export default Error;
