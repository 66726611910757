import { DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { memo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { clearCache } from "../../api/cache";
import { HttpClient } from "../../api/httpClient";
import ExpensesStatusSelector from "../../components/inputs/selectors/ExpensesStatusSelector";
import TextInput from "../../components/inputs/text-input";
import CloseableDialogTitle from "../../modals/CloseableDialogTitle";
import { getExpensesByResult } from "../../modals/expenses/failed-resume/utils";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openElement } from "../../store/features/base/modalsSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { updateManyExpensesByItems } from "../../store/features/expensesSlice";

const EditExpensesStatusWrapper = ({
  role,
  renderComponent = ({ openModal = () => {} }) => {},
  onSuccess = () => {},
}) => {
  const dispatch = useDispatch();
  const [expensesIds, setExpensesIds] = useState([]);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const abortControllerRef = useRef(null);

  const resetState = () => {
    setStatus("");
    setLoading(false);
    setExpensesIds([]);
  };

  const onClose = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    resetState();
    setOpened(false);
  };

  const handleSave = async () => {
    setLoading(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const { data: responseData } = await HttpClient.put({
        url: "/expenses",
        body: {
          ids: expensesIds,
          fields: {
            status: status || undefined,
            rejectionReason: status === "rejected" ? comment : null,
          },
        },
        signal: abortController.signal,
        params: {
          role,
        },
      });
      clearCache("/expenses");

      const { allOk, successNumber, withErrors, failed, successIds, success } =
        getExpensesByResult(responseData);
      if (allOk) {
        dispatch(
          openSnackbar({
            message:
              successNumber === 1
                ? "Despesa atualizada"
                : `${successNumber} despesas atualizadas`,
          })
        );
      } else if (withErrors) {
        dispatch(
          openElement({
            name: "modalExpensesFailedResume",
            payload: {
              expenses: failed,
              title: "Algumas despesas não foram editadas",
              role,
            },
          })
        );
      }

      dispatch(
        updateManyExpensesByItems({
          role,
          expenses: success || [],
        })
      );
      onSuccess();
      onClose();
    } catch (error) {
      dispatch(setError({ error }));
    }
    setLoading(false);
  };

  return (
    <>
      {renderComponent({
        openModal: (expensesIds) => {
          setExpensesIds(expensesIds);
          setOpened(true);
        },
      })}
      {opened && (
        <Dialog fullWidth open={opened} maxWidth={"xs"}>
          {Boolean(opened) && (
            <>
              {loading && <LinearProgress />}
              <CloseableDialogTitle
                sx={{
                  fontSize: "1.2rem",
                }}
                onClose={onClose}
              >
                Alterar status de {expensesIds.length} despesa
                {expensesIds?.length > 1 ? "s" : ""}
              </CloseableDialogTitle>
              <DialogContent sx={{ pb: 5 }}>
                <ExpensesStatusSelector
                  variant={"filled"}
                  value={status}
                  onChange={setStatus}
                  label="Escolha um novo status"
                  margin={"normal"}
                  readOnly={loading}
                  blackList={["pending"]}
                />
                <FormHelperText>
                  Apenas os usuários podem enviar suas despesas para aprovação.
                </FormHelperText>
                {status === "rejected" && (
                  <TextInput
                    autoFocus
                    hiddenLabel
                    label={""}
                    value={comment}
                    onChange={setComment}
                    margin={"normal"}
                    placeholder={"Informe um motivo (opcional)"}
                    multiline
                    rows={5}
                    RightIcon={() =>
                      Boolean(comment) && (
                        <IconButton size="small" onClick={() => setComment("")}>
                          <DeleteOutlineOutlined fontSize="small" />
                        </IconButton>
                      )
                    }
                    variant={"filled"}
                  />
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  disabled={!status || loading}
                  variant="contained"
                  disableElevation
                  onClick={handleSave}
                >
                  Salvar
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default memo(EditExpensesStatusWrapper);
