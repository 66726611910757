import { GppBad, InfoOutlined, WarningOutlined } from "@mui/icons-material";

export const expenseInitialState = {
  date: new Date(),
  amount: "",
  currency: "BRL",
  category: "",
  payment: "",
  project: "",
  notes: "",
  dailyNumber: "1",
  dailyRate: "",
  fullTank: true,
};
export const routeInitialState = {
  date: new Date(),
  routePolicy: "",
  project: "",
  distance: "",
  amount: "",
  notes: "",
};
export const newRouteInitialState = {
  date: new Date(),
  snippet_id: "",
  route_policy_id: "",
  project_id: "",
  distance: "",
  notes: "",
  refundable: true,
  request_id: "",
};

export const expenseItemData = {
  id: "",
  date: new Date().toISOString(),
  created_at: new Date().toISOString(),
  refundable: true,
  notes: "",
  is_route: false,
  type_id: "",
  currency: "",
  account_currency: "",
  amount: "",
  amount_converted: "",
  receipts: [],
  total_receipts: 0,
  project_name: "",
  distance: 0,
  waypoints: [],
  request_id: "",
  payment_type: "",
  payment_type_name: "",
  required_receipt: false,
  status: "",
  from: "",
  to: "",
  hidden: false,
  processingStatus: null,

  //sync
  sync: false,
  sync_error: null,
};

export const requestItemData = {
  id: "",
  created_at: new Date(),
  title: "",
  obs: "",
  status: "O",
  total_amount: 0,
  total_refundable: 0,
  total_expenses: 0,
  events: [],
  step_info: null,
  //sync
  processingStatus: null,
};
export const approvalRequestItemData = {
  id: "",
  created_at: new Date(),
  title: "",
  obs: "",
  total_amount: 0,
  total_refundable: 0,
  total_expenses: 0,
  user: {},
  expenses: [],
};

export const ADVANCES_STATUS_UTILS = {
  labels: {
    pending: "Em aprovação",
    available: "Disponível",
    approved: "Aprovado",
    rejected: "Rejeitado",
    paid: "Pago",
    finished: "Finalizado",
  },
  colors: {
    pending: "warning",
    available: "success",
    approved: "success",
    rejected: "error",
    paid: "primary",
    finished: "primary",
  },
};

export const EXPENSES_STATUS_UTILS = {
  labels: {
    opened: "Para enviar",
    rejected: "Rejeitada",
    pending: "Em aprovação",
    approved: "Aprovada",
    finished: "Finalizada",
  },
  colors: {
    opened: "default",
    rejected: "error",
    pending: "warning",
    approved: "success",
    finished: "primary",
  },
};

export const ADMIN_RULE_MESSAGE_TYPES = {
  info: {
    Icon: InfoOutlined,
    text: "Informação",
    color: "info",
  },
  warning: {
    Icon: WarningOutlined,
    text: "Aviso",
    color: "warning",
  },
  error: {
    Icon: GppBad,
    text: "Fora da política",
    color: "error",
  },
};

export const initialUsersFilter = {
  all: true,
  users: [],
  groups: [],
  filterKey: "all",
};

export const initialUsersVisibility = {
  all: true,
  users: [],
  groups: [],
  orgs: [],
};
