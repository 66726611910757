import { Circle, DeleteOutlineOutlined } from "@mui/icons-material";
import { IconButton, MenuItem, Stack, Tooltip } from "@mui/material";
import React from "react";
import { EXPENSES_STATUS_UTILS } from "../../../utils/initialStates";
import SelectInput from "../Select";

const ExpensesStatusSelector = ({
  margin,
  label = "Status",
  value,
  onChange = () => {},
  readOnly,
  disabled,
  variant,
  size,
  blackList = [],
  disableClearable,
}) => {
  return (
    <SelectInput
      label={label}
      margin={margin}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      disabled={disabled}
      variant={variant}
      size={size}
      endIcon={
        value &&
        !disableClearable && (
          <Tooltip placement="right" title="Limpar campo">
            <IconButton
              sx={{ mr: 2 }}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onChange("");
              }}
            >
              <DeleteOutlineOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        )
      }
      renderValue={(option) =>
        Boolean(option) && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1.4}
            fontWeight={"500"}
          >
            <Circle
              color={EXPENSES_STATUS_UTILS.colors[option]}
              sx={{
                fontSize: ".5rem",
              }}
            />
            {EXPENSES_STATUS_UTILS.labels[option]}
          </Stack>
        )
      }
    >
      {Object.keys(EXPENSES_STATUS_UTILS.labels)
        ?.filter((status) => !blackList.includes(status))
        .map((status) => (
          <MenuItem
            key={status}
            value={status}
            sx={{ gap: 2, fontWeight: "600" }}
          >
            <Circle
              color={EXPENSES_STATUS_UTILS.colors[status]}
              sx={{
                fontSize: ".5rem",
              }}
            />
            {EXPENSES_STATUS_UTILS.labels[status]}
          </MenuItem>
        ))}
    </SelectInput>
  );
};

export default ExpensesStatusSelector;
