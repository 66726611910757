import { API_BASE_URL } from "../../api/urls";
import { store } from "../../store/store";

export const getProfilePicture = (userId, isMe = false) => {
  const state = store.getState();
  const hasPhoto = isMe ? state.account?.baseInfo.has_profile_photo : true;
  const version = state.account?.baseInfo.profile_photo_version || 0;
  if (hasPhoto) {
    return `${API_BASE_URL}/photo/user/${userId}?version=${version}`;
  } else {
    return undefined;
  }
};
