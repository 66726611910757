import {
  AttachFileOutlined,
  DeleteOutlineOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import PdfIcon from "../../../../assets/icons/pdf_icon.svg";
import "../../../../main.css";
import { openElement } from "../../../../store/features/base/modalsSlice";
import Fancybox from "../../../fancybox";
import ImageZoom from "../../../images/ImageZoom";

function SingleReceiptView({ file, onClickRemove, isEditable }) {
  const dispatch = useDispatch();

  const isPDF = useMemo(
    () => file?.mimetype === "application/pdf" || !file?.is_image,
    [file]
  );

  const openInNew = () => {
    window.open(file?.url);
  };
  const handlePdfClick = () => {
    dispatch(
      openElement({
        name: "pdfViewer",
        payload: {
          url: file?.url,
          filename: file?.filename,
        },
      })
    );
  };

  return (
    <Box
      overflow="hidden"
      display="flex"
      width={"100%"}
      alignItems={"center"}
      justifyContent="center"
      pb={10}
    >
      <Box
        display={"flex"}
        alignItems="center"
        bgcolor={"rgb(0,0,0,.7)"}
        position={"absolute"}
        width="100%"
        zIndex={3}
        top={0}
        py={1}
        height={40}
        px={2}
      >
        <Tooltip title={file?.filename}>
          <Typography
            onClick={handlePdfClick}
            sx={{
              cursor: "pointer",
              ":hover": {
                textDecoration: "underline",
              },
            }}
            noWrap
            fontWeight={"600"}
            variant="body2"
            color={"white"}
          >
            {file?.filename}
          </Typography>
        </Tooltip>
        <Box flex={1} />
        <Button
          sx={{ color: "#FFF" }}
          size="small"
          onClick={() => {
            if (isPDF) {
              handlePdfClick();
              return;
            }
            const receipt = document.getElementById(`receipt-${file?.id}`);
            if (receipt) {
              receipt.click();
            }
          }}
        >
          Visualizar
        </Button>
        <Tooltip title="Abrir em outra aba">
          <IconButton onClick={openInNew} size="small" sx={{ color: "white" }}>
            <OpenInNewOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
        {isEditable && (
          <Tooltip title="Remover">
            <IconButton
              onClick={(event) => onClickRemove(event, file?.id)}
              size="small"
              sx={{ color: "white" }}
            >
              <DeleteOutlineOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {file?.mimetype?.includes("application") ? (
        isPDF ? (
          <img
            onClick={handlePdfClick}
            height="70%"
            width={"70%"}
            src={PdfIcon}
            style={{ cursor: "pointer", marginTop: "40px" }}
          />
        ) : (
          <AttachFileOutlined fontSize="large" />
        )
      ) : (
        <Fancybox>
          <a
            id={`receipt-${file?.id}`}
            data-fancybox="gallery"
            href={file?.url}
          >
            <ImageZoom src={file?.url} width="100%" />
          </a>
        </Fancybox>
      )}
    </Box>
  );
}

export default memo(SingleReceiptView);
