export const getUsernameInitials = (name = "", lastname = "") => {
  if (!isNaN(parseInt(name))) {
    const numberString = name.trim().toString();
    const firstTwoDigits = numberString.slice(0, 2);
    return parseInt(firstTwoDigits, 10);
  }
  return `${name ? name.trim()[0] : ""}${lastname ? lastname.trim()[0] : ""}`;
};

export function htmlToText(html) {
  // Cria um elemento temporário para processar o HTML
  let div = document.createElement("div");
  div.innerHTML = html;

  // Função recursiva para percorrer e processar o conteúdo
  function processNode(node) {
    let text = "";

    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        text += child.textContent;
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        // Adiciona quebras de linha para elementos de bloco comuns
        if (
          ["P", "DIV", "BR", "LI", "H1", "H2", "H3", "H4", "H5", "H6"].includes(
            child.tagName
          )
        ) {
          text += "\n";
        }
        text += processNode(child);
      }
    });

    return text;
  }

  // Processa o conteúdo e remove espaços em branco extra
  let text = processNode(div);
  text = text.replace(/\s+\n/g, "\n").replace(/\n\s+/g, "\n").trim();

  return text;
}

export function isValidRole(role = "") {
  return ["financial", "personal", "approver", "director"].includes(role);
}

export function getLastSegment(url) {
  // Remove a parte da query string (após '?') se existir
  let cleanUrl = url.split("?")[0];

  // Remove a barra final, se existir
  if (cleanUrl.endsWith("/")) {
    cleanUrl = cleanUrl.slice(0, -1);
  }

  // Divide a string limpa em um array de segmentos com base nas barras
  const segments = cleanUrl.split("/");

  // Retorna o último segmento do array
  return segments[segments.length - 1];
}

export function formatRoleText(role) {
  switch (role) {
    case "personal":
      return "Pessoal";

    case "approver":
      return "Aprovador";

    case "financial":
      return "Financeiro";

    default:
      return role;
  }
}

export function capitalizeFirstLetter(string = "") {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

export const formatPaymentTypeText = (type = "") => {
  switch (type) {
    case "advance":
      return "Adiantamentos";
    case "cash":
      return "Dinheiro";
    case "credit-card":
      return "Cartão de crédito";
    case "debit-card":
      return "Cartão de débito";

    default:
      return type;
  }
};

export const formatAdvancesStatusText = (status = "", role) => {
  switch (status) {
    case "all":
      return "Todos";
    case "available":
      return "Disponíveis";
    case "pending":
      return "Em aprovação";
    case "approved":
      return role === "financial" ? "Para pagar" : "Aprovados";
    case "rejected":
      return "Rejeitados";
    case "paid":
      return "Pagos";
    case "finished":
      return "Finalizados";

    default:
      return status;
  }
};

export const formatFilterToVisibility = (filter = {}) => {
  return {
    to: filter?.filterKey,
    items: Array.isArray(filter[filter?.filterKey])
      ? (filter[filter?.filterKey] || [])?.map((item) =>
          typeof item === "string" ? item : item?._id
        )
      : [],
  };
};
export const formatVisibilityToFilter = (visibility = {}) => {
  const items = visibility?.items || [];
  return {
    all: visibility?.to === "all",
    filterKey: visibility?.to,
    groups: items,
    users: items,
    orgs: items,
  };
};

export const formatVisibilityPropsToId = (visibility = {}) => {
  return {
    orgs: visibility?.orgs?.map((item) => item?._id) || null,
    groups: visibility?.groups?.map((item) => item?._id) || null,
    users: visibility?.users?.map((item) => item?._id) || null,
  };
};

export const formatRolesToString = (rules = {}) => {
  if (rules?.master) {
    return "Proprietário";
  }
  let items = ["Prestador"];
  if (rules?.approver?.enabled) {
    items.push("Aprovador");
  }
  if (rules?.financial?.enabled) {
    items.push("Financeiro");
  }
  if (rules?.insights?.enabled) {
    items.push("Analista");
  }
  if (rules?.director?.enabled) {
    items.push("Diretor");
  }
  if (rules?.billing?.enabled) {
    items.push("Faturamento");
  }
  if (rules?.admin?.enabled) {
    items.push("Administrador");
  }
  return items.join(", ");
};

export function formatarCPF(value = "") {
  // Remove qualquer caractere que não seja um número
  let cpf = value?.replace(/\D/g, "");

  // Verifica se o CPF tem 11 dígitos
  if (cpf?.length !== 11) {
    return value;
  }

  // Formata o CPF (000.000.000-00)
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export const mapItemsToId = (items = []) => {
  return (items || [])?.map((item) => item?._id);
};

export const formatNumber = (value = "") => {
  return parseFloat(value || "").toLocaleString("pt-BR", {
    style: "decimal",
    maximumFractionDigits: 1,
  });
};
