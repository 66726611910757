import { HelpOutlineOutlined } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { memo } from "react";

const DetailLabel = ({
  children,
  width = 170,
  label = "",
  helperText = "",
  sx,
  labelSx,
}) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"} sx={sx}>
      <Typography
        width={width}
        variant="body2"
        fontSize={".9rem"}
        fontWeight={"500"}
        color={"text.secondary"}
        sx={labelSx}
        component={"div"}
        display={"flex"}
        alignItems={"center"}
      >
        {label}
        {Boolean(helperText) && (
          <Tooltip arrow enterDelay={0} title="Texto de ajuda">
            <HelpOutlineOutlined
              color="action"
              sx={{
                cursor: "pointer",
                opacity: 0.5,
                fontSize: "1rem",
                ":hover": { opacity: 1 },
              }}
            />
          </Tooltip>
        )}
      </Typography>
      <Box flex={1}>
        <Typography
          sx={{ cursor: "text" }}
          component={"div"}
          fontWeight={children ? "600" : "400"}
          variant="body2"
          display={"flex"}
          alignItems={"center"}
          color={children ? "text.primary" : "text.secondary"}
          fontSize={".9rem"}
        >
          {children || "--"}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(DetailLabel);
