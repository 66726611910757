import { ExpandLess, ExpandMore, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeError } from "../../store/features/base/errorBaseSlice";

function ModalErrorBase(props) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.errorBase.open);

  const errorBase = useSelector((state) => state.errorBase);
  const { title, subtitle, error, content, config } = errorBase || {};

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (!open) {
      setShowMore(false);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      transitionDuration={100}
      PaperProps={{
        sx: { maxWidth: 480 },
      }}
    >
      {open && (
        <>
          <DialogTitle
            fontSize={"1.25rem"}
            display={"flex"}
            alignItems="center"
            fontWeight={"600"}
            height={65}
          >
            <InfoOutlined sx={{ mr: 2 }} color="error" />
            <span>{error?.response?.data?.title || title}</span>
          </DialogTitle>
          <DialogContent>
            {content}
            <Typography
              component={"div"}
              fontSize={".975rem"}
              variant="body1"
              sx={{ wordBreak: "break-word", opacity: 0.8 }}
              fontWeight={"500"}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    error?.response?.data?.message?.toString() ||
                    error?.response?.data?.toString() ||
                    subtitle ||
                    "",
                }}
              />
              {process.env.REACT_APP_ENV === "development" && (
                <>
                  <br />
                  <Typography component={"span"} fontSize={"0.8rem"} mt={1}>
                    Código do erro:{" "}
                    <Typography
                      component={"span"}
                      variant="inherit"
                      fontWeight={600}
                    >
                      {error?.response?.data?.code || error?.code || "NO_CODE"}
                    </Typography>
                  </Typography>
                </>
              )}
            </Typography>
            {process.env.REACT_APP_ENV === "development" && (
              <>
                {error?.response?.data?.details && (
                  <Box>
                    <Button
                      onClick={() => setShowMore(!showMore)}
                      size="small"
                      sx={{ px: 1, ml: -1, mt: 0.5 }}
                      endIcon={
                        showMore ? (
                          <ExpandLess fontSize="small" />
                        ) : (
                          <ExpandMore fontSize="small" />
                        )
                      }
                    >
                      Mais detalhes
                    </Button>
                    <Collapse in={showMore}>
                      <Typography mt={1} variant="body2" fontWeight={"500"}>
                        - {error?.response?.data?.details}
                      </Typography>
                    </Collapse>
                  </Box>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => dispatch(closeError())}>
              Ok
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default ModalErrorBase;
