import {
  Close,
  FileDownloadOutlined,
  OpenInNew,
  PictureAsPdf,
  Print,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Fade,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useCallback } from "react";

const Header = ({
  url = "",
  filename = "",
  scrolled,
  visible,
  onMouseEnter,
  onMouseLeave,
  onClose = () => {},
}) => {
  const handleDownload = useCallback(() => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename; // Define o nome do arquivo baixado
    link.target = "_blank";
    document.body.appendChild(link); // Adiciona o link ao DOM
    link.click(); // Simula o clique para iniciar o download
    document.body.removeChild(link); // Remove o link do DOM após o clique
  }, [url, filename]);

  const handlePrint = useCallback(() => {
    const printWindow = window.open(url, "_blank");
    if (printWindow) {
      printWindow.focus();
      printWindow.onload = () => {
        printWindow.print();
      };
    }
  }, [url]);

  const handleOpenInNewTab = useCallback(() => {
    window.open(url, "_blank");
  }, [url]);

  return (
    <Fade timeout={400} in={visible}>
      <AppBar
        id="header-tools"
        position="sticky"
        variant="outlined"
        color="transparent"
        sx={{
          bgcolor: scrolled ? "rgba(0,0,0,.4)" : "transparent",
          border: "none",
          color: "#FFF",
        }}
        onMouseEnter={onMouseEnter} // Ativa visibilidade
        onMouseLeave={onMouseLeave} // Permite ocultação
        onClick={(e) => e.stopPropagation()}
      >
        <Toolbar disableGutters sx={{ px: 2, gap: 1 }}>
          <IconButton onClick={onClose} color="inherit">
            <Close />
          </IconButton>
          <PictureAsPdf sx={{ color: "#FFB4AB" }} />
          <Typography fontWeight={600} fontSize={"1rem"}>
            {filename || ""}
          </Typography>
          <Box flex={1} />
          <Tooltip title="Fazer o download">
            <IconButton onClick={handleDownload} color="inherit">
              <FileDownloadOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir">
            <IconButton onClick={handlePrint} color="inherit">
              <Print />
            </IconButton>
          </Tooltip>
          <Tooltip title="Abrir em outra janela">
            <IconButton onClick={handleOpenInNewTab} color="inherit">
              <OpenInNew />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </Fade>
  );
};

export default memo(Header);
