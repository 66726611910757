import { clearCache as resetCache } from "./cache";
import { HttpClient } from "./httpClient";

const createAction = async ({
  url = "",
  method = "post",
  body = {},
  params = {},
  signal,
  clearCache = false,
  shouldCache = false,
  cacheKeys = [],
}) => {
  const res = await HttpClient[method]({
    url,
    body,
    params,
    signal,
    shouldCache,
  });
  if (clearCache && cacheKeys?.length) {
    cacheKeys.forEach((cacheKey) => {
      resetCache(cacheKey);
    });
  }
  return res?.data;
};

export const APIActions = {
  expenses: {
    add: async (expenseData, role = "expenses") =>
      await createAction({
        url: "/expenses",
        body: expenseData,
        params: { role },
        method: "post",
        clearCache: true,
        cacheKeys: ["/expenses", "/advances"],
      }),
    remove: async ({ expensesIds = [], role, signal }) =>
      await createAction({
        url: `/expenses/delete`,
        body: {
          ids: expensesIds,
        },
        params: {
          role,
        },
        method: "post",
        cacheKeys: ["/expenses", "/advances"],
        clearCache: true,
        signal,
      }),
    update: async ({ expenseId = "", role, signal, changes = {} }) =>
      await createAction({
        url: `/expenses/${expenseId}`,
        body: changes,
        params: {
          role,
        },
        method: "put",
        cacheKeys: ["/expenses", "/advances"],
        clearCache: true,
        signal,
      }),
    send: async ({
      expensesIds,
      requestTitle = "",
      fromDate,
      toDate,
      role = "expenses",
    }) =>
      await createAction({
        url: "/expenses/sendToApproval",
        body: {
          ids: expensesIds,
          reportTitle: requestTitle,
          fromDate,
          toDate,
        },
        params: { role },
        method: "post",
        clearCache: true,
        cacheKeys: ["/expenses", "/advances"],
      }),
    cancelSend: async ({ expensesIds, role = "expenses", signal }) =>
      await createAction({
        url: "/expenses/cancelSend",
        body: {
          ids: expensesIds,
        },
        signal,
        params: { role },
        method: "post",
        clearCache: true,
        cacheKeys: ["/expenses"],
      }),
    approve: async ({ expensesIds = [], comment = "", role = "approver" }) =>
      await createAction({
        url: `/expenses/approve`,
        body: {
          ids: expensesIds,
          comment,
        },
        params: { role },
        method: "post",
        clearCache: true,
        cacheKeys: ["/expenses"],
      }),
    reject: async ({ expensesIds = [], comment = "", role = "approver" }) =>
      await createAction({
        url: `/expenses/reject`,
        body: {
          ids: expensesIds,
          comment,
        },
        params: { role },
        method: "post",
        clearCache: true,
        cacheKeys: ["/expenses"],
      }),
    detail: async ({ expenseId = "", signal, role = "expenses" }) =>
      await createAction({
        url: `/expenses/${expenseId}`,
        params: { role },
        signal,
        method: "get",
        shouldCache: true,
      }),
  },
  receipts: {
    scan: async ({ blob, filename, expenseId, receiptId, createdAt }) => {
      const formData = new FormData();
      formData.append("receipt", blob, filename);
      return await createAction({
        url: `/expenses/${expenseId}/receipts/upload/scan`,
        body: formData,
        params: { receipt_id: receiptId, createdAt },
        method: "post",
        clearCache: true,
        cacheKeys: ["/expenses"],
        shouldCache: false,
      });
    },
  },
  reports: {
    createRegular: async ({
      expensesIds,
      fromDate,
      toDate,
      role,
      title = "",
    }) =>
      await createAction({
        url: "/reports/regular",
        method: "post",
        body: {
          _ids: expensesIds,
          fromDate,
          toDate,
        },
        params: {
          title,
          role,
        },
        cacheKeys: ["/reports"],
        clearCache: true,
      }),
    createAccounting: async ({
      fromDate,
      toDate,
      role,
      title = "",
      finishExpenses,
      expensesIds,
      currency,
    }) =>
      await createAction({
        url: "/reports/accounting",
        method: "post",
        body: {
          _ids: expensesIds,
          fromDate,
          toDate,
        },
        params: {
          title,
          role,
          currency,
          finishExpenses,
        },
        cacheKeys: ["/reports"],
        clearCache: true,
      }),
    remove: async ({ reportsIds = [], role, signal }) =>
      await createAction({
        url: `/reports/delete`,
        body: {
          ids: reportsIds,
        },
        params: {
          role,
        },
        method: "post",
        cacheKeys: ["/reports"],
        clearCache: true,
        signal,
      }),
  },
  advances: {
    detail: async ({
      advanceId = "",
      signal,
      role = "personal",
      shouldCache = true,
    }) =>
      await createAction({
        url: `/advances/${advanceId}`,
        params: { role },
        signal,
        method: "get",
        shouldCache,
      }),
    remove: async ({ advanceId = "", signal, role = "personal" }) =>
      await createAction({
        url: `/advances/${advanceId}`,
        params: { role },
        signal,
        method: "delete",
        shouldCache: true,
      }),
    paid: async ({ advanceId = "", signal, role = "financial" }) =>
      await createAction({
        url: `/advances/${advanceId}/paid`,
        params: { role },
        signal,
        method: "put",
        shouldCache: true,
      }),
    finish: async ({ advanceId = "", signal, role = "financial" }) =>
      await createAction({
        url: `/advances/${advanceId}/finish`,
        params: { role },
        signal,
        method: "put",
      }),
    create: async ({
      signal,
      role,
      user,
      name = "",
      currency = "",
      notes = "",
      amount = 0,
      group,
    }) =>
      await createAction({
        url: "/advances",
        method: "post",
        body: {
          user, // Para quem é o adiantamento (somente financeiro pode preencher)
          name,
          notes,
          amount, // Valor solicitado
          currency,
          group,
        },
        params: {
          role,
        },
        signal,
        clearCache: true,
        cacheKeys: ["/advances"],
      }),
    requestAgain: async ({ advanceId, role = "personal" }) =>
      await createAction({
        url: `/advances/${advanceId}/sendToApproval`,
        params: { role },
        method: "put",
        clearCache: true,
        cacheKeys: ["/advances"],
      }),
    approve: async ({ advancesId, role = "approver" }) =>
      await createAction({
        url: `/advances/${advancesId}/approve`,
        params: { role },
        method: "post",
        clearCache: true,
        cacheKeys: ["/advances"],
      }),
    reject: async ({ advancesId, comment = "", role = "approver" }) =>
      await createAction({
        url: `/advances/${advancesId}/reject`,
        body: {
          comment,
        },
        params: { role },
        method: "post",
        clearCache: true,
        cacheKeys: ["/advances"],
      }),
  },
  transactions: {
    detail: async ({ transactionId = "", signal, role = "financial" }) =>
      await createAction({
        url: `/transactions/${transactionId}`,
        params: { role },
        signal,
        method: "get",
        shouldCache: true,
      }),
    remove: async ({ transactionsIds = [], role = "financial", signal }) =>
      await createAction({
        url: `/transactions`,
        params: {
          ids: transactionsIds,
          role,
        },
        method: "delete",
        cacheKeys: ["/transactions"],
        clearCache: true,
        signal,
      }),
    undoMatch: async ({ transactionId = "", signal, role = "financial" }) =>
      await createAction({
        url: `/transactions/${transactionId}/undoMatch`,
        params: { role },
        signal,
        method: "put",
        shouldCache: false,
        clearCache: true,
        cacheKeys: ["/transactions"],
      }),
    createExpenses: async ({
      transactionsIds = [],
      signal,
      role = "financial",
    }) =>
      await createAction({
        url: `/transactions/createExpenses`,
        body: {
          ids: transactionsIds,
        },
        params: { role },
        signal,
        method: "post",
        shouldCache: false,
        clearCache: true,
        cacheKeys: ["/transactions"],
      }),
  },
};
